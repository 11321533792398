'use client';

import { useEffect } from 'react';
import Rollbar from 'rollbar';

import { DefaultErrorPage } from '@/components/compat/DefaultErrorPage';
import { baseConfig } from '@/lib/rollbarConfig';

// This error page catches errors generated in the root layout and template
// only. They are not caught by the page-specific error.tsx because they
// occur before the page is defined.
export default function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    // We don't have access to the global via hook here because we
    // only see errors here from root layout and template.
    const rollbar = new Rollbar({
      ...baseConfig,
      accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
    });
    rollbar.error(error);
  }, [error]);

  return (
    <html lang="en">
      <body>
        <DefaultErrorPage reset={reset} />
      </body>
    </html>
  );
}
